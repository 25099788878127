/* global analytics */
import React from "react";
import styled from "@mui/system/styled";
import { withRouter } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Tooltip,
  OutlinedInput,
  FilledInput,
} from "@mui/material";
import { useAccountState } from "../state/store";
import { getFormPublicApi, submitFormPublicApi } from "../api/FormsApi";
import { useFormik } from "formik";
import * as Yup from "yup";
import CroppedImg from "./CroppedImg";
import { DatePicker } from "@mui/x-date-pickers";
import AddressField from "./AdressField";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { externalUrl, haveCommonKey, sort_by_order } from "../utils/utils";
import { CssTextField } from "./CssTextField";

const Form = (props) => {
  const [form, initializeForm, initializeUser] = useAccountState((state) => [
    state.form,
    state.initializeForm,
    state.initializeUser,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  const [errored, setErrored] = React.useState(false);

  React.useEffect(() => {
    initializeUser(props.user);
    let form_uuid = props.match.params.form_uuid;
    getFormPublicApi(form_uuid).then((resp) => {
      initializeForm(resp.data);
      setLoading(false);
      analytics.identify(resp.data.user);
    });
  }, []);

  const [validationSchema, setValidationSchema] = React.useState(undefined);

  const isRequriedSubKey = (k) => {
    if (k.includes("_")) {
      let sub_keys = k.split("_");
      let sub_key = sub_keys[sub_keys.length - 1];
      return requiredSubKeys.includes(sub_key);
    } else {
      return true;
    }
  };

  React.useEffect(() => {
    const validationSchema = Yup.object().shape(
      form.fields?.reduce(
        (schema, field) => {
          var fieldVal;
          if (field.type === "RQ") {
            fieldVal = field.recipes?.reduce((acc, recipe) => {
              return {
                ...acc,
                [`${field.uuid}_${recipe.uuid}_quantity`]: Yup.number(),
              };
            }, {});
          } else if (field.type === "CC") {
            fieldVal = ccFields.reduce((acc, { name }) => {
              return {
                ...acc,
                [`${field.uuid}_${name}`]: Yup.string(),
              };
            }, {});
          } else if (field.type === "TI") {
            fieldVal = {
              [`${field.uuid}_datetime`]: Yup.string(),
              [`${field.uuid}_name`]: Yup.string(),
              [`${field.uuid}_address`]: Yup.object(),
            };
          } else {
            fieldVal = { [field.uuid]: Yup.string() };
          }
          if (field.required) {
            fieldVal = Object.entries(fieldVal).reduce((acc, [key, val]) => {
              return {
                ...acc,
                [key]: isRequriedSubKey(key) ? val.required("Required") : val,
              };
            }, {});
          }
          return { ...schema, ...fieldVal };
        },
        {
          Name: Yup.string().required("Required"),
          Email: Yup.string().email("Invalid email").required("Required"),
          "Event Date": Yup.string().required("Required"),
        }
      )
    );
    setValidationSchema(validationSchema);
  }, [form.fields]);

  const [initialValues, setInitialValues] = React.useState(undefined);

  const fieldInitialValues = (field) => {
    if (field.type === "MC") {
      return { [field.uuid]: field.options && field.options[0] };
    } else if (field.type === "CC") {
      return ccFields.reduce((values, { name, label }) => {
        return { ...values, [`${field.uuid}_${name}`]: "" };
      }, {});
    } else if (field.type === "ED") {
      return { [field.uuid]: new Date() };
    } else if (field.type === "RQ") {
      return field.recipes?.reduce((values, recipe) => {
        return {
          ...values,
          [`${field.uuid}_${recipe.uuid}_quantity`]: "",
        };
      }, {});
    } else if (field.type === "TI") {
      return {
        [`${field.uuid}_datetime`]: "",
        [`${field.uuid}_name`]: "",
        [`${field.uuid}_address`]: {},
      };
    } else {
      return { [field.uuid]: "" };
    }
  };

  React.useEffect(() => {
    var initialValues = { Name: "", Email: "", "Event Date": new Date() };
    if (form.fields) {
      initialValues = {
        ...initialValues,
        ...form.fields?.reduce((values, field) => {
          return { ...values, ...fieldInitialValues(field) };
        }, {}),
      };
    }

    setInitialValues(initialValues);
  }, [form.fields]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setSubmitting(true);
      submitFormPublicApi({
        ...values,
        form_uuid: form.uuid,
      })
        .then((resp) => {
          setSubmitted(true);
          setSubmitting(false);
        })
        .catch((err) => {
          setSubmitted(false);
          setErrored(true);
          setSubmitting(false);
        });
    },
  });

  return loading || !formik.values || !formik.initialValues ? (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <CircularProgress sx={{ m: "auto", mt: "25%" }} color="info" />
    </Box>
  ) : (
    <Box>
      <Box
        sx={{
          maxWidth: "800px",
          ml: "auto",
          mr: "auto",
          mt: "1rem",
          mb: "1rem",
          pb: "10rem",
          overflow: "auto",
          // scrollMargin: "50vh",
          height: "90%",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: "1rem",
                pb: "2rem",
                backgroundColor: form.background_color,
              }}
            >
              <Grid container spacing={1} justifyContent={"center"}>
                {form.return_url && (
                  <Grid item xs={12} container justifyContent={"flex-start"}>
                    <Grid item xs="auto">
                      <Tooltip title="Return to site">
                        <IconButton
                          color={"info"}
                          variant="outlined"
                          href={externalUrl(form.return_url)}
                        >
                          <ArrowBackOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                {form.logo && (
                  <Grid item xs={12} container justifyContent={"center"}>
                    <Grid item xs={6}>
                      <Box display={"flex"}>
                        <CroppedImg
                          img={form.logo}
                          marginLeft="auto"
                          marginRight="auto"
                          marginBottom="2rem"
                          marginTop="2rem"
                          maxHeight="300px"
                          maxWidth="100%"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                {!submitted && !errored && (
                  <>
                    {form.intro_text && (
                      <Grid item xs={12} container justifyContent={"center"}>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            sx={{
                              color: form.primary_text_color,
                              fontFamily: form.primary_text_font,
                              mb: "1rem",
                            }}
                          >
                            {form.intro_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6} container spacing={4}>
                      <FormField
                        field={{
                          name: "Name",
                          required: true,
                          type: "GT",
                          uuid: "Name",
                        }}
                        formik={formik}
                        form={form}
                      />
                      <FormField
                        field={{
                          name: "Email",
                          required: true,
                          type: "GT",
                          uuid: "Email",
                        }}
                        formik={formik}
                        form={form}
                      />
                      <FormField
                        field={{
                          name: "Event Date",
                          required: true,
                          type: "ED",
                          uuid: "Event Date",
                        }}
                        formik={formik}
                        form={form}
                      />
                      {form.fields?.map((field, idx) => (
                        <FormField
                          field={field}
                          formik={formik}
                          key={field.uuid}
                          form={form}
                        />
                      ))}
                    </Grid>
                    {haveCommonKey(formik.errors, formik.touched) && (
                      <Grid item xs={12} container justifyContent={"center"}>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "error.main",
                              fontFamily: form.secondary_text_font,
                            }}
                          >
                            Please address the errors above. * indicates a
                            required field.
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12} container justifyContent={"center"}>
                      {!submitting ? (
                        <Grid item xs="auto">
                          <Button
                            sx={{ mt: "1rem" }}
                            color={"info"}
                            variant="contained"
                            onClick={formik.handleSubmit}
                          >
                            Submit
                          </Button>
                        </Grid>
                      ) : (
                        <Grid item xs="auto">
                          <CircularProgress color="info" />
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                {submitted && !errored && (
                  <Grid item xs={12} container justifyContent={"center"}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: form.primary_text_color,
                          fontFamily: form.primary_text_font,
                          textAlign: "center",
                        }}
                      >
                        {form.confirmation_text
                          ? form.confirmation_text
                          : "Thank you for your submission."}
                      </Typography>
                    </Grid>
                    {form.return_url && (
                      <Grid item xs={12} container justifyContent={"center"}>
                        <Grid item xs="auto">
                          <Button
                            sx={{ mt: "1rem" }}
                            color={"info"}
                            variant="outlined"
                            href={externalUrl(form.return_url)}
                          >
                            Return to Site
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
                {errored && (
                  <Grid item xs={12} container justifyContent={"center"}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        sx={{
                          color: form.primary_text_color,
                          fontFamily: form.primary_text_font,
                        }}
                      >
                        An error has occurred and your submission was not
                        recorded. If you continue to have issues, please contact
                        us directly.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent={"center"}>
                      <Grid item xs="auto">
                        <Button
                          sx={{ mt: "1rem" }}
                          color={"info"}
                          variant="outlined"
                          onClick={() => {
                            setErrored(false);
                            setSubmitted(false);
                          }}
                        >
                          Try Again
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const requiredSubKeys = ["name", "email", "quantity", "datetime"];

const FormField = ({ field, formik, form }) => {
  return (
    <Grid item xs={12} container spacing={1} alignItems={"center"}>
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              color: form.primary_text_color,
              fontFamily: form.primary_text_font,
            }}
          >
            {field.name}
            {field.required && "*"}
          </Typography>
        </Grid>
        {field.description && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{
                color: form.secondary_text_color,
                fontFamily: form.secondary_text_font,
              }}
            >
              {field.description}
            </Typography>
          </Grid>
        )}
      </Grid>
      {(field.type === "GT" || field.type === "GN") && (
        <CssTextField
          fullWidth
          id={field.uuid}
          name={field.uuid}
          size="small"
          variant="filled"
          value={formik.values[field.uuid]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched[field.uuid] && Boolean(formik.errors[field.uuid])
          }
          helperText={formik.touched[field.uuid] && formik.errors[field.uuid]}
          type={field.type === "GN" ? "number" : undefined}
          multiline={field.type === "GT" && field.text_type === "Paragraph"}
          minRows={
            field.type === "GT" && field.text_type === "Paragraph" ? 4 : 1
          }
          focusColor={form.primary_text_color}
        />
      )}
      {field.type === "MC" && formik.values[field.uuid] && (
        <FormControl
          fullWidth
          sx={{
            ".MuiFilledInput-root": {
              after: {
                borderBottomColor: "green", // This tries to mimic a focus style
              },
              "&:hover": {
                ".MuiFilledInput-underline:before": {
                  borderBottom: "2px solid blue", // Example for hover state
                },
              },
              "&.Mui-focused .MuiFilledInput-underline:after": {
                borderBottomColor: "red", // Color for focus state
              },
            },
          }}
        >
          <Select
            id={field.uuid}
            value={formik.values[field.uuid]}
            onChange={formik.handleChange}
            error={
              formik.touched[field.uuid] && Boolean(formik.errors[field.uuid])
            }
            name={field.uuid}
            size="small"
            variant="filled"
            sx={{
              fontFamily: form.secondary_text_font,
              "&:focus": {
                backgroundColor: form.primary_text_color,
                borderColor: form.primary_text_color,
              },
              "&:after": {
                borderColor: form.primary_text_color,
              },
            }}
          >
            {field.options.map((option) => {
              return (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{
                    fontFamily: form.secondary_text_font,
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                  }}
                >
                  {option}
                </MenuItem>
              );
            })}
          </Select>
          {formik.errors[field.uuid] && Boolean(formik.touched[field.uuid]) && (
            <Typography
              variant="caption"
              color="error"
              sx={{ ml: "1rem", mt: ".25rem" }}
            >
              {formik.errors[field.uuid]}
            </Typography>
          )}
        </FormControl>
      )}
      {field.type === "CC" &&
        ccFields.map(({ name, label }) => (
          <Grid item xs={6}>
            <SubTextField
              sub_name={name}
              label={label}
              field={field}
              formik={formik}
            />
          </Grid>
        ))}
      {field.type === "ED" && (
        <DatePicker
          name={field.uuid}
          id={field.uuid}
          value={formik.values[field.uuid]}
          onChange={(val) => {
            formik.setFieldValue(field.uuid, val);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched[field.uuid] && Boolean(formik.errors[field.uuid])
          }
          renderInput={(params) => (
            <CssTextField
              {...params}
              fullWidth
              size="small"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                style: {
                  fontFamily: form.secondary_text_font,
                },
              }}
              focusColor={form.primary_text_color}
            />
          )}
          fullWidth
        />
      )}
      {field.type === "RQ" &&
        field.recipes?.sort(sort_by_order).map((recipe) => (
          <>
            <Grid item xs={9}>
              <Typography
                sx={{
                  color: form.secondary_text_color,
                  fontFamily: form.secondary_text_font,
                }}
              >
                {recipe.name}
                {field.required && "*"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <RecipeQuantityTextField
                formik={formik}
                field={field}
                sub_name={`${recipe.uuid}_quantity`}
              />
            </Grid>
          </>
        ))}
      {field.type === "TI" && (
        <TimelineItemFields formik={formik} field={field} />
      )}
    </Grid>
  );
};

export const ccFields = [
  { name: "name", label: "Name" },
  { name: "email", label: "Email" },
  { name: "phone", label: "Phone" },
  { name: "company", label: "Company" },
  { name: "website", label: "Website" },
  { name: "role", label: "Role" },
];

export const tiFields = [
  { name: "datetime", label: "Date" },
  { name: "name", label: "Name" },
  { name: "address", label: "Address" },
];

const SubTextField = ({ formik, field, sub_name, label }) => {
  const [form] = useAccountState((state) => [state.form]);

  const name = `${field.uuid}_${sub_name}`;
  return (
    <CssTextField
      fullWidth
      id={name}
      name={name}
      size="small"
      label={`${label}${
        field.required && requiredSubKeys.includes(sub_name) ? "*" : ""
      }`}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="filled"
      InputLabelProps={{
        sx: {
          fontFamily: form.secondary_text_font,
          color: form.secondary_text_color,
        },
      }}
      focusColor={form.primary_text_color}
    />
  );
};

const RecipeQuantityTextField = ({ formik, field, sub_name }) => {
  const [form] = useAccountState((state) => [state.form]);

  const name = `${field.uuid}_${sub_name}`;
  const [error, setError] = React.useState(false);
  return (
    <CssTextField
      fullWidth
      id={name}
      name={name}
      size="small"
      label={`Qty.`}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      type="number"
      variant="filled"
      InputLabelProps={{
        sx: {
          fontFamily: form.secondary_text_font,
          color: form.secondary_text_color,
        },
      }}
      focusColor={form.primary_text_color}
    />
  );
};

const TimelineItemDatetime = ({ formik, field, sub_name }) => {
  const [form] = useAccountState((state) => [state.form]);

  const name = `${field.uuid}_${sub_name}`;
  return (
    <>
      <CssTextField
        type="datetime-local"
        name={name}
        id={name}
        defaultValue={formik.values[name]}
        onChange={(e) => {
          formik.setFieldValue(name, e.target.value);
        }}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        fullWidth
        size="small"
        variant="filled"
        inputProps={{
          style: {
            fontFamily: form.secondary_text_font,
          },
        }}
        focusColor={form.primary_text_color}
      />
      {formik.errors[name] && formik.touched[name] && (
        <Typography
          variant="caption"
          color="error"
          sx={{ ml: "1rem", mt: ".25rem" }}
        >
          {formik.errors[name]}
        </Typography>
      )}
    </>
  );
};

const TimelineItemFields = ({ formik, field }) => {
  const [form] = useAccountState((state) => [state.form]);
  const handleChange = (newVal) => {
    formik.setFieldValue(`${field.uuid}_address`, newVal);
  };
  return (
    <>
      <Grid item xs={12}>
        <TimelineItemDatetime
          formik={formik}
          field={field}
          sub_name="datetime"
        />
      </Grid>
      <Grid item xs={12}>
        <SubTextField
          formik={formik}
          field={field}
          sub_name="name"
          label="Location Name"
        />
      </Grid>
      <Grid item xs={12}>
        <AddressField
          handleChange={handleChange}
          id={`${field.uuid}_address`}
          variant="filled"
          size="small"
          InputLabelProps={{
            sx: {
              fontFamily: form.secondary_text_font,
              color: form.secondary_text_color,
            },
          }}
          focusColor={form.primary_text_color}
        />
      </Grid>
    </>
  );
};

export default withRouter(Form);
