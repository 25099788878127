import React, { useRef } from "react";
import { useFormik } from "formik";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  quantityField,
  priceField,
  isOptionalField,
  isSelectedField,
  amendableField,
  isPercentField,
  percentField,
  formatStringForId,
  sort_by_order,
  DecimalWrapper,
  percentFieldWithMarkup,
  markupPercentageField,
} from "../utils/utils.js";

import {
  Grid,
  Box,
  Paper,
  TextField,
  Typography,
  IconButton,
  Collapse,
  Divider,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fade,
  Switch,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Menu,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Chip,
  Link,
  Grow,
  CircularProgress,
  Alert,
  Breadcrumbs,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import StatusIcon from "./StatusIcon.js";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import ArrowUpwardOutlinedIcon from "@mui/icons-material/ArrowUpwardOutlined";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import LocalFloristOutlinedIcon from "@mui/icons-material/LocalFloristOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

import RecipeItems from "./RecipeItems.js";
import InspirationImgSection from "./InspirationImgs";
import AmendmentBadge from "./AmendmentBadge.js";
import CroppedImg from "./CroppedImg.js";
import { sort } from "fast-sort";
import {
  eventItemTypeName,
  priceBelowSuggested,
  priceIsDerived,
  priceNotEqualSuggested,
} from "../utils/event_items_utils.js";
import { useAccountState } from "../state/store.js";
import { recipeFlowers, recipeMaterials } from "../utils/item_utils.js";
import RecipeImgs from "./RecipeImgs.js";
import { Delete, Fullscreen } from "@mui/icons-material";
import EventItemDividerButtons from "./EventItemDividerButtons.js";
import { setIn } from "formik";
import {
  addEventItemApi,
  removeEventItemApi,
  updateEventItemApi,
} from "../api/EventsApi.js";
import Tags from "./Tags.js";
import { ApplyRecipeTemplateDialog } from "./RecipeTemplates.js";
import RichTextField, {
  RichTextFieldEditable,
  RichTextFieldView,
} from "./RichTextField.js";
import { DefaultStyles } from "../utils/proposal_utils.js";

const EventItem = (props) => {
  const [
    addEventItem,
    addOtherCost,
    addRecipeImg,
    applyRecipeTemplate,
    back_office,
    contractExists,
    deleteEventItem,
    deleteEventItemInState,
    deleteRecipeImg,
    deleteTaxGroupFromEvent,
    draggable,
    duplicateEventItem,
    event,
    event_items,
    event_version,
    eventItemGroupPrice,
    eventItemGroupProfit,
    eventItemGroupTotalPriceWithTax,
    eventItemGroupTotalProfit,
    eventItemHasAmendments,
    eventItemPrice,
    eventItemPriceIsLow,
    eventItemProfit,
    eventItemTotalPriceWithTax,
    eventItemTotalProfit,
    eventLocked,
    formatCurrency,
    getEventItemByUuid,
    handleEventItemDragEnd,
    otherCostPriceWithTax,
    proposal,
    recipeBuildLabor,
    recipeInstallLabor,
    recipePrice,
    recipeProfit,
    recipeTotalPriceWithTax,
    recipeTotalProfit,
    suggestedEventItemPrice,
    suggestedEventItemPriceTotal,
    suggestedRecipePrice,
    suggestedTaxPrice,
    taxPrice,
    totalEventItemCost,
    totalEventItemGroupCost,
    totalRecipeCost,
    unitEventItemCost,
    unitEventItemGroupCost,
    unitOtherCostPrice,
    unitRecipeCost,
    suggestedOtherCostPrice,
    updateEventItem,
    updateEventItemOrder,
    updateRecipeImgInState,
  ] = useAccountState((state) => [
    state.addEventItem,
    state.addOtherCost,
    state.addRecipeImg,
    state.applyRecipeTemplate,
    state.back_office,
    state.contractExists,
    state.deleteEventItem,
    state.deleteEventItemInState,
    state.deleteRecipeImg,
    state.deleteTaxGroupFromEvent,
    state.draggable,
    state.duplicateEventItem,
    state.event,
    state.event_items,
    state.event_version,
    state.eventItemGroupPrice,
    state.eventItemGroupProfit,
    state.eventItemGroupTotalPriceWithTax,
    state.eventItemGroupTotalProfit,
    state.eventItemHasAmendments,
    state.eventItemPrice,
    state.eventItemPriceIsLow,
    state.eventItemProfit,
    state.eventItemTotalPriceWithTax,
    state.eventItemTotalProfit,
    state.eventLocked,
    state.formatCurrency,
    state.getEventItemByUuid,
    state.handleEventItemDragEnd,
    state.otherCostPriceWithTax,
    state.proposal,
    state.recipeBuildLabor,
    state.recipeInstallLabor,
    state.recipePrice,
    state.recipeProfit,
    state.recipeTotalPriceWithTax,
    state.recipeTotalProfit,
    state.suggestedEventItemPrice,
    state.suggestedEventItemPriceTotal,
    state.suggestedRecipePrice,
    state.suggestedTaxPrice,
    state.taxPrice,
    state.totalEventItemCost,
    state.totalEventItemGroupCost,
    state.totalRecipeCost,
    state.unitEventItemCost,
    state.unitEventItemGroupCost,
    state.unitOtherCostPrice,
    state.unitRecipeCost,
    state.suggestedOtherCostPrice,
    state.updateEventItem,
    state.updateEventItemOrder,
    state.updateRecipeImgInState,
  ]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [imgs_open, setImgsOpen] = React.useState(false);
  const [drawer_open, setDrawerOpen] = React.useState(false);

  const [adding_item, setAddingItem] = React.useState(false);
  const [creating_new_item, setCreatingNewItem] = React.useState(false);
  const [deleting_event_item, setDeletingEventItem] = React.useState(false);

  const addRecipeImg_ = (data) => {
    addRecipeImg(props.event_item, data);
  };

  const updateRecipeImg = (data) => {
    updateRecipeImgInState({ event_item_uuid: props.event_item.uuid, ...data });
  };

  const deleteRecipeImg_ = (uuid) => {
    deleteRecipeImg(props.event_item, uuid);
  };

  const handleBuildLaborUpdate = (e) => {
    if (e.target.value !== "") {
      updateEventItem({
        uuid: props.event_item.uuid,
        build_labor_time_estimate_minutes: e.target.value,
      });
    }
  };

  const handleInstallationLaborUpdate = (e) => {
    if (e.target.value !== "") {
      updateEventItem({
        uuid: props.event_item.uuid,
        instalation_labor_time_estimate_minutes: e.target.value,
      });
    }
  };

  const orderDown = (e) => {
    e.stopPropagation();
    let data = {
      uuid: props.event_item.uuid,
      action: "down",
    };
    updateEventItemOrder(data);
  };

  const orderUp = (e) => {
    e.stopPropagation();
    let data = {
      uuid: props.event_item.uuid,
      action: "up",
    };
    updateEventItemOrder(data);
  };

  const removeEventItem = () => {
    if (props.event_item.event_item_type === "TA") {
      event_version.tax_groups.forEach((tax_group) => {
        if (
          tax_group.taxes
            .map((tax) => tax.uuid)
            .includes(props.event_item.tax_profile)
        ) {
          deleteTaxGroupFromEvent(tax_group.uuid);
        }
      });
    }
    if (
      !contractExists() ||
      props.event_item.quantity === 0 ||
      (isOptionalField(props.event_item, true) &&
        !isSelectedField(props.event_item, true))
    ) {
      deleteEventItem(props.event_item.uuid);
    } else {
      updateEventItem({ uuid: props.event_item.uuid, quantity: 0 });
    }
  };

  const resetEventItem = (e) => {
    e.stopPropagation();
    if (props.event_item.quantity === 0) {
      toggleDeletingEventItem();
    } else {
      updateEventItem({
        uuid: props.event_item.uuid,
        amend_price: null,
        amend_quantity: null,
        amend_is_optional: null,
        amend_is_selected: null,
        amend_percent: null,
        amend_sales_tax: null,
        amend_is_percent: null,
        amend_markup_percentage: null,
      });
    }
  };

  const round_formatter = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  });

  const toggleAddingItem = () => {
    setAddingItem(!adding_item);
  };

  const toggleCreatingNewItem = () => {
    setCreatingNewItem(!creating_new_item);
    setAddingItem(false);
  };

  const toggleDeletingEventItem = () => {
    setDeletingEventItem(!deleting_event_item);
  };

  const toggleDrawerOpen = () => {
    setDrawerOpen(!drawer_open);
  };

  const toggleIsOptional = () => {
    updateEventItem({
      uuid: props.event_item.uuid,
      is_optional: !isOptionalField(props.event_item),
    });
  };

  const toggleIsSelected = () => {
    updateEventItem({
      uuid: props.event_item.uuid,
      is_selected: !isSelectedField(props.event_item),
    });
  };

  const toggleOpen = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const strikeThrough = () => {
    if (props.event_item.event_item_group) {
      let event_item_group = getEventItemByUuid(
        props.event_item.event_item_group
      );
      return (
        (isOptionalField(event_item_group) &&
          !isSelectedField(event_item_group)) ||
        quantityField(event_item_group) === 0
      );
    } else {
      return (
        (isOptionalField(props.event_item) &&
          !isSelectedField(props.event_item)) ||
        quantityField(props.event_item) === 0
      );
    }
  };

  const sortRecipeImgs = (recipe_imgs) => {
    return sort(recipe_imgs).by([{ asc: (r) => r.position }]);
  };

  const _eventItemTypeName = () => {
    return eventItemTypeName(props.event_item);
  };

  const resetEventItemQuantity = () => {
    if (props.event_item.quantity === 0) {
      toggleDeletingEventItem();
    } else {
      updateEventItem({
        uuid: props.event_item.uuid,
        amend_quantity: null,
      });
    }
  };

  const [priceWarningDialogOpen, setPriceWarningDialogOpen] =
    React.useState(false);

  const togglePriceWarningDialog = (e) => {
    e.stopPropagation();
    setPriceWarningDialogOpen(!priceWarningDialogOpen);
  };

  const renderRecipeHeadline = (inDrawer, drag_handle_props) => {
    return (
      <Box display="flex">
        <Grid container alignItems="center" justifyContent={"space-between"}>
          <Grid
            item
            sm={5}
            xs={12}
            container
            spacing={0.5}
            onClick={toggleOpen}
          >
            <Grid item xs={12} container justifyContent={"space-between"}>
              <Grid item xs={11} container alignItems={"center"}>
                {!inDrawer &&
                  (!props.event_item.is_template ||
                    props.event_item.event_item_group) && (
                    <Grid item xs={1}>
                      <div {...drag_handle_props}>
                        <DragIndicatorIcon
                          sx={{ color: "info.light", mr: ".25rem" }}
                        />
                      </div>
                    </Grid>
                  )}
                <Grid item xs={11}>
                  <EventItemName
                    event_item={props.event_item}
                    updateEventItem={updateEventItem}
                  />
                </Grid>
              </Grid>

              {!inDrawer && (
                <Grid item xs={1}>
                  <Box
                    sx={{
                      height: "100%",
                      display: { sm: "none", xs: "flex" },
                    }}
                  >
                    <ExpandButton
                      expand={drawer_open}
                      size="large"
                      onClick={toggleDrawerOpen}
                      sx={{
                        m: "auto",
                      }}
                    >
                      <KeyboardArrowLeftOutlinedIcon />
                    </ExpandButton>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} container justifyContent={"space-between"}>
              <Grid item xs="auto" container spacing={0.25}>
                <Grid item xs="auto">
                  <Box
                    sx={{
                      backgroundColor: "white",
                      borderColor: "info.main",
                      border: "solid 1px",
                      width: ".9rem",
                      height: ".9rem",
                      p: ".25rem",
                    }}
                    borderRadius="50%"
                  >
                    <Tooltip title={eventItemTypeName(props.event_item)}>
                      <Typography
                        sx={{
                          p: 0,
                          m: 0,
                          lineHeight: ".9rem",
                          textAlign: "center",
                        }}
                      >
                        {eventItemTypeName(props.event_item).substring(0, 1)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
                <StatusIcon
                  icon={ArticleOutlinedIcon}
                  activeTooltip={`${_eventItemTypeName()} is contracted`}
                  inactiveTooltip={`${_eventItemTypeName()} is not contracted`}
                  alertTooltip={`${_eventItemTypeName()} has amendments`}
                  active={contractExists() && props.event_item.quantity > 0}
                  alert={eventItemHasAmendments(props.event_item)}
                />
                {eventItemHasAmendments(props.event_item) &&
                  (props.event_item.event_item_type !== "GR" ||
                    props.event_item.hide_sub_items ||
                    props.event_item.hide_sub_item_prices) && (
                    <Grid item xs="auto">
                      <Tooltip
                        title={
                          props.event_item.quantity === 0
                            ? `Delete uncontracted ${_eventItemTypeName()}`
                            : `Reset ${_eventItemTypeName()} (undo amendments to price, quantity, optional, and selected)`
                        }
                      >
                        <IconButton
                          size="small"
                          sx={{ color: "error.main", pt: ".25rem" }}
                          onClick={resetEventItem}
                          disabled={eventLocked()}
                          id={formatStringForId(
                            props.event_item.name + "-reset-amendments-button"
                          )}
                        >
                          <RestartAltOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <DeleteEventItemDialog
                        name={props.event_item.name}
                        open={deleting_event_item}
                        handleCancel={toggleDeletingEventItem}
                        handleSubmit={removeEventItem}
                        item_name={_eventItemTypeName()}
                        item_will_be_deleted={
                          !contractExists() || props.event_item.quantity === 0
                        }
                        event_item={props.event_item}
                      />
                    </Grid>
                  )}
                <StatusIcon
                  icon={ImageOutlinedIcon}
                  activeTooltip={`${_eventItemTypeName()} has inspiration images`}
                  inactiveTooltip={`No inspiration images added to ${_eventItemTypeName()}`}
                  active={props.event_item.recipe_imgs.length > 0}
                />
                {props.event_item.event_item_type === "RE" && (
                  <>
                    <StatusIcon
                      icon={LocalFloristOutlinedIcon}
                      activeTooltip="Recipe has flowers"
                      inactiveTooltip="No flowers added to recipe"
                      active={recipeFlowers(props.event_item).length > 0}
                    />
                    <StatusIcon
                      icon={LiquorOutlinedIcon}
                      activeTooltip="Recipe has hard goods"
                      inactiveTooltip="No hard goods added to recipe"
                      active={recipeMaterials(props.event_item).length > 0}
                    />
                    <StatusIcon
                      icon={ConstructionOutlinedIcon}
                      activeTooltip="Recipe has labor"
                      inactiveTooltip="No labor added to recipe"
                      active={
                        props.event_item.build_labor_time_estimate_minutes >
                          0 ||
                        props.event_item
                          .instalation_labor_time_estimate_minutes > 0
                      }
                    />
                  </>
                )}
              </Grid>
              <Grid item xs="auto" display="flex">
                {!props.event_item.is_percent && (
                  <EventItemQuantity
                    event_item={props.event_item}
                    updateEventItem={updateEventItem}
                    event={event}
                    locked={eventLocked()}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={2} xs={4} onClick={toggleOpen}>
            <RecipeHeaderValue
              value={formatCurrency(totalEventItemCost(props.event_item))}
              label="Cost"
              strikeThrough={strikeThrough()}
            />
          </Grid>
          <Grid item sm={2} xs={4} onClick={toggleOpen}>
            <RecipeHeaderValue
              value={formatCurrency(
                eventItemTotalPriceWithTax(props.event_item)
              )}
              label={proposal.spread_tax ? "Price (incl. tax)" : "Price"}
              strikeThrough={strikeThrough()}
              endAdornment={
                priceBelowSuggested(
                  props.event_item,
                  suggestedEventItemPrice(props.event_item),
                  back_office.price_error_tolerance
                ) ? (
                  <>
                    <Tooltip
                      title={`Price is set below suggested retail price of ${formatCurrency(
                        suggestedEventItemPriceTotal(props.event_item)
                      )}. Expand card to edit price.`}
                    >
                      <IconButton
                        onClick={togglePriceWarningDialog}
                        size="small"
                      >
                        <WarningAmberRoundedIcon
                          fontSize=".25rem"
                          sx={{ color: "error.main" }}
                          id={formatStringForId(
                            props.event_item.name +
                              "-price-below-suggested-icon"
                          )}
                        />
                      </IconButton>
                    </Tooltip>
                    <EventItemPriceWarningDialog
                      open={priceWarningDialogOpen}
                      handleCancel={togglePriceWarningDialog}
                      event_item={props.event_item}
                    />
                  </>
                ) : null
              }
            />
          </Grid>
          <Grid item sm={2} xs={4} onClick={toggleOpen}>
            <RecipeHeaderValue
              value={formatCurrency(eventItemTotalProfit(props.event_item))}
              label="Profit"
              strikeThrough={strikeThrough()}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: { sm: "flex", xs: "none" } }}
            onClick={toggleOpen}
          >
            <ExpandButton
              id={formatStringForId(props.event_item.name + "-expand-button")}
              expand={open}
              size="large"
              onClick={toggleOpen}
            >
              <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
            </ExpandButton>
          </Grid>
          {(open && !fullScreen) | inDrawer ? renderSubtotals() : null}
        </Grid>
      </Box>
    );
  };

  const renderSubtotals = () => {
    return (
      <Grid item xs={12} container>
        <Grid item xs={5}></Grid>
        <Grid item sm={6} xs={12} container>
          <Grid item xs={4}>
            {["RE", "GR"].includes(props.event_item.event_item_type) && (
              <SubtotalValue
                id={formatStringForId(props.event_item.name + "-cost-ea")}
                label="Cost/ea."
                value={formatCurrency(unitEventItemCost(props.event_item))}
              />
            )}
            {props.event_item.event_item_type === "OT" &&
              !props.event_item.is_percent && (
                <>
                  <Grid container justifyContent="center">
                    <Grid item xs="auto" display="flex">
                      <EventItemNumber
                        id="cost"
                        value={props.event_item.cost}
                        updateEventItem={updateEventItem}
                        event={event}
                        event_item={props.event_item}
                        number
                        inputPropsSx={{ p: ".3rem 0 .3rem 0" }}
                        sx={{ width: "5rem", ml: "1rem" }}
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    sx={{ textAlign: "center", color: "text.secondary" }}
                  >
                    Cost/ea.
                  </Typography>
                </>
              )}
            {props.event_item.event_item_type === "OT" &&
              props.event_item.is_percent && (
                <EventItemCostPercent event_item={props.event_item} />
              )}
          </Grid>
          <Grid
            item
            xs={4}
            id={formatStringForId(props.event_item.name + "-price-ea")}
          >
            <Grid container justifyContent="center">
              <Grid item xs="auto" display="flex">
                <EventItemPrice
                  event_item={props.event_item}
                  updateEventItem={updateEventItem}
                  price={eventItemPrice(props.event_item)}
                  suggested_price={suggestedEventItemPrice(props.event_item)}
                  event={event}
                  contract_exists={contractExists()}
                  inputPropsSx={{ p: ".3rem 0 .3rem 0" }}
                  sx={{ width: "5rem", ml: "1rem" }}
                  locked={eventLocked()}
                />
              </Grid>
            </Grid>
            <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
              Price/ea.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <SubtotalValue
              id={formatStringForId(props.event_item.name + "-profit-ea")}
              label="Profit/ea."
              value={formatCurrency(eventItemProfit(props.event_item))}
            />
          </Grid>
        </Grid>
        {props.event_item.event_item_type === "OT" && (
          <>
            <Grid item xs={5}></Grid>
            <Grid item sm={6} xs={12} container>
              <Grid item xs={4} container justifyContent={"flex-end"}>
                <Grid container justifyContent="center">
                  <Grid item xs="auto">
                    <EventItemMarkupPercentage event_item={props.event_item} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const InspirationImgListItemProps = [
    { position: 1, cols: 1, rows: 1 },
    { position: 2, cols: 1, rows: 1 },
    { position: 3, cols: 1, rows: 1 },
    { position: 4, cols: 1, rows: 1 },
    { position: 5, cols: 1, rows: 1 },
  ];

  const [duplicating, setDuplicating] = React.useState(false);
  const [savingLoading, setSavingLoading] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);
  const [recipeTemplate, setRecipeTemplate] = React.useState(null);

  const templateSavedAction = (
    <React.Fragment>
      <IconButton size="small" href="/data?initial_tab=recipe-templates">
        <ExitToAppOutlinedIcon fontSize="inherit" sx={{ color: "white" }} />
      </IconButton>
    </React.Fragment>
  );

  const [applyingTemplate, setApplyingTemplate] = React.useState(false);
  const [applyLoading, setApplyLoading] = React.useState(false);
  const toggleApplyingTemplate = () => {
    setApplyingTemplate(!applyingTemplate);
  };
  const handleApplyTemplate = (data) => {
    setApplyLoading(true);
    toggleApplyingTemplate();
    applyRecipeTemplate(
      { ...data, event_item_uuid: props.event_item.uuid },
      () => {
        setApplyLoading(false);
      }
    );
  };

  React.useEffect(() => {
    if (recipeTemplate) {
      let event_item = getEventItemByUuid(recipeTemplate.uuid);
      setRecipeTemplate(event_item);
    }
  }, [event_items]);

  const renderRecipeDetails = () => {
    return (
      <Box>
        {!props.event_item.event_item_group && (
          <Grid container>
            <Grid item xs="auto">
              <EventItemSwitch
                id="is_optional"
                event_item={props.event_item}
                updateEventItem={updateEventItem}
                event={event}
                true_label="Optional"
                false_label="Not Optional"
                locked={eventLocked()}
                disabled={props.event_item.event_item_group}
                amendable
              />
            </Grid>
            <Grid item xs="auto">
              <EventItemSwitch
                id="is_selected"
                event_item={props.event_item}
                updateEventItem={updateEventItem}
                event={event}
                true_label="Selected"
                false_label="Not Selected"
                locked={eventLocked()}
                disabled={
                  !isOptionalField(props.event_item) ||
                  props.event_item.event_item_group
                }
                amendable
              />
            </Grid>
            <Grid item xs="auto">
              <EventItemSwitch
                id="sales_tax"
                event_item={props.event_item}
                updateEventItem={updateEventItem}
                event={event}
                label="Sales Tax"
                true_label="Sales Tax Applicable"
                false_label="Sales Tax Not Applicable"
                locked={eventLocked()}
                amendable
              />
            </Grid>
            {props.event_item.event_item_type === "GR" && (
              <>
                <Grid item xs="auto">
                  <EventItemSwitch
                    id="hide_sub_items"
                    event_item={props.event_item}
                    updateEventItem={updateEventItem}
                    event={event}
                    true_label="Sub Items"
                    false_label="Hide Sub Items"
                    locked={eventLocked() || contractExists()}
                    reversed
                  />
                </Grid>
                {!props.event_item.hide_sub_items && (
                  <Grid item xs="auto">
                    <EventItemSwitch
                      id="hide_sub_item_prices"
                      event_item={props.event_item}
                      updateEventItem={updateEventItem}
                      event={event}
                      true_label="Sub Item Prices"
                      false_label="Hide Sub Item Prices"
                      locked={eventLocked() || contractExists()}
                      reversed
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        )}
        <RecipeDetailsSection
          title="Inspiration Photos & Notes"
          id={formatStringForId(
            props.event_item.name + "-inspiration-photos-notes-section"
          )}
        >
          <RecipeImgs
            addImg={addRecipeImg_}
            updateImg={updateRecipeImg}
            removeImg={deleteRecipeImg_}
            imgs={props.event_item.recipe_imgs}
            id={formatStringForId(
              props.event_item.name + "-inspiration-photos"
            )}
            zoom
          />
          <Divider
            sx={{
              mt: ".5rem",
              mb: ".5rem",
              backgroundColor: "grey",
            }}
          />
          <NotesSection
            label="Proposal Notes (can optionally be added to your proposals)"
            value={props.event_item.proposal_notes_rich_text}
            event_recipe_update={updateEventItem}
            id="proposal_notes_rich_text"
            event_recipe_uuid={props.event_item.uuid}
          />
          <Divider
            sx={{
              mt: ".5rem",
              mb: ".5rem",
              backgroundColor: "grey",
            }}
          />
          <NotesSection
            label="Florist Notes (for your eyes only)"
            value={props.event_item.florist_notes_rich_text}
            event_recipe_update={updateEventItem}
            id="florist_notes_rich_text"
            event_recipe_uuid={props.event_item.uuid}
          />
        </RecipeDetailsSection>
        {props.event_item.event_item_type === "RE" && (
          <>
            {" "}
            <RecipeDetailsSection
              title="Flowers"
              id={formatStringForId(props.event_item.name + "-items-section")}
            >
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={12}>
                  <RecipeItems
                    item_type="FL"
                    items={props.event_item.recipe_items}
                    recipe={props.event_item}
                    id={formatStringForId(props.event_item.name + "-flowers")}
                  />
                </Grid>
              </Grid>
            </RecipeDetailsSection>
            <RecipeDetailsSection
              title="Hard Goods"
              id={formatStringForId(
                props.event_item.name + "-materials-section"
              )}
            >
              <Grid container justifyContent="space-around" alignItems="center">
                <Grid item xs={12}>
                  <RecipeItems
                    item_type="MA"
                    items={props.event_item.recipe_items}
                    recipe={props.event_item}
                    id={formatStringForId(props.event_item.name + "-materials")}
                  />
                </Grid>
              </Grid>
            </RecipeDetailsSection>
            <RecipeDetailsSection
              title="Labor"
              id={formatStringForId(props.event_item.name + "-labor-section")}
            >
              <Grid
                container
                alignItems="center"
                columnSpacing="1rem"
                sx={{ maxWidth: "400px" }}
              >
                <Grid item xs={5} sm={4} display="flex"></Grid>
                <Grid item xs={4} sm={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                      color: "text.secondary",
                    }}
                  >
                    Rate
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                      color: "text.secondary",
                    }}
                  >
                    Cost
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={4}>
                  <TextField
                    name="unit_labor_estimate_minutes"
                    label="Build (minutes)"
                    id="unit_labor_estimate_minutes"
                    value={props.event_item.build_labor_time_estimate_minutes}
                    onChange={handleBuildLaborUpdate}
                    variant="standard"
                    fullWidth
                    sx={{
                      ml: "auto",
                      mr: "auto",
                      maxWidth: "6rem",
                      mb: "1rem",
                    }}
                    inputProps={{
                      id: formatStringForId(
                        props.event_item.name +
                          "-build-labor-estimate-minutes-field"
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                      pl: { sm: "30px", xs: 0 },
                    }}
                  >
                    {formatCurrency(
                      back_office?.build_labor_cost_hourly
                        ? back_office.build_labor_cost_hourly
                        : props.event_item.template_back_office
                            .build_labor_cost_hourly
                    )}
                    /hr
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                    }}
                  >
                    {formatCurrency(recipeBuildLabor(props.event_item))}
                  </Typography>
                </Grid>
                <Grid item xs={5} sm={4}>
                  <TextField
                    label="Install (minutes)"
                    value={
                      props.event_item.instalation_labor_time_estimate_minutes
                    }
                    onChange={handleInstallationLaborUpdate}
                    variant="standard"
                    fullWidth
                    sx={{
                      ml: "auto",
                      mr: "auto",
                      maxWidth: "6rem",
                      mb: "1rem",
                    }}
                    id="unit_labor_estimate_minutes"
                    inputProps={{
                      id: formatStringForId(
                        props.event_item.name +
                          "-install-labor-estimate-minutes-field"
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                      pl: { sm: "30px", xs: 0 },
                    }}
                  >
                    {formatCurrency(
                      back_office?.install_labor_cost_hourly
                        ? back_office.install_labor_cost_hourly
                        : props.event_item.template_back_office
                            .install_labor_cost_hourly
                    )}
                    /hr
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={4} display="flex">
                  <Typography
                    sx={{
                      alignItems: "center",
                      ml: "auto",
                      mr: "auto",
                    }}
                  >
                    {formatCurrency(recipeInstallLabor(props.event_item))}
                  </Typography>
                </Grid>
              </Grid>
            </RecipeDetailsSection>
          </>
        )}
        <Divider sx={{ mt: ".5rem", mb: ".5rem" }} />
        <Grid container justifyContent="space-between" spacing={1}>
          <Grid item xs="auto" container spacing={1}>
            {!props.event_item.is_template && (
              <Grid item display="flex">
                {savingLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      setSavingLoading(true);
                      addEventItem(
                        {
                          duplicate_uuid: props.event_item.uuid,
                          is_template: true,
                          event_item_type: props.event_item.event_item_type,
                        },
                        (ei) => {
                          setRecipeTemplate(ei);
                          setSaving(true);
                          setSavingLoading(false);
                        }
                      );
                    }}
                    color="info"
                    sx={{ mr: "auto", ml: "auto" }}
                    variant="outlined"
                    size="small"
                    id="save-recipe-button"
                  >
                    Save As Template
                  </Button>
                )}
                {saving && recipeTemplate && (
                  <CreateEventItemTemplateDialog
                    open={saving}
                    handleCancel={() => {
                      let template_uuid = recipeTemplate.uuid;
                      setSaving(false);
                      setRecipeTemplate(null);
                      deleteEventItemInState(template_uuid);
                      if (getEventItemByUuid(template_uuid)) {
                        setSaved(true);
                      }
                    }}
                    event_item={recipeTemplate}
                  />
                )}
                <Snackbar
                  open={saved}
                  autoHideDuration={6000}
                  onClose={() => {
                    setSaved(false);
                  }}
                  message={"Template Saved!"}
                  action={templateSavedAction}
                />
              </Grid>
            )}
            {!props.event_item.is_template && (
              <Grid item display="flex">
                {duplicating ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      setDuplicating(true);
                      duplicateEventItem(props.event_item, () => {
                        setDuplicating(false);
                      });
                    }}
                    color="info"
                    sx={{ mr: "auto", ml: "auto" }}
                    variant="outlined"
                    size="small"
                    disabled={eventLocked()}
                    id="duplicate-recipe-button"
                  >
                    Duplicate {_eventItemTypeName()}
                  </Button>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs="auto" container spacing={1}>
            {!props.event_item.is_template && (
              <Grid item display="flex">
                {applyLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={toggleApplyingTemplate}
                    color="info"
                    variant="outlined"
                    size="small"
                    disabled={eventLocked()}
                    id="delete-recipe-button"
                  >
                    Apply Template
                  </Button>
                )}
                <ApplyRecipeTemplateDialog
                  open={applyingTemplate}
                  handleCancel={toggleApplyingTemplate}
                  handleSubmit={handleApplyTemplate}
                />
              </Grid>
            )}
            {(!props.event_item.is_template ||
              props.event_item.event_item_group) && (
              <Grid item>
                <Button
                  onClick={toggleDeletingEventItem}
                  color="error"
                  variant="outlined"
                  size="small"
                  disabled={eventLocked()}
                  id="delete-recipe-button"
                >
                  Delete {_eventItemTypeName()}
                </Button>
                <DeleteEventItemDialog
                  name={props.event_item.name}
                  open={deleting_event_item}
                  handleCancel={toggleDeletingEventItem}
                  handleSubmit={removeEventItem}
                  item_name={_eventItemTypeName()}
                  item_will_be_deleted={
                    !contractExists() ||
                    props.event_item.quantity === 0 ||
                    (isOptionalField(props.event_item, true) &&
                      !isSelectedField(props.event_item, true))
                  }
                  event_item={props.event_item}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const [inGroup, setInGroup] = React.useState(false);
  React.useEffect(() => {
    setInGroup(props.event_item.event_item_group ? true : false);
  }, [props.event_item.event_item_group]);

  const renderRecipe = (drag_handle_props, is_dragging) => {
    return (
      <Box
        sx={
          inGroup || props.event_item.is_template ? undefined : { pl: "1rem" }
        }
      >
        {(!props.event_item.is_template ||
          props.event_item.event_item_group) && (
          <EventItemDividerButtons
            index={props.index}
            hidden={is_dragging}
            backgroundColor={props.backgroundColor}
            disableAddSection={props.disableAddSection}
            disableAddGroup={props.disableAddGroup}
            eventItemGroup={props.eventItemGroup}
          />
        )}
        <Paper
          sx={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            border: eventItemPriceIsLow(props.event_item)
              ? "solid 1px red"
              : undefined,
            borderLeft: "3px solid",
            borderLeftColor: "info.light",
            boxShadow: 3,
          }}
        >
          <Box sx={{ p: ".5rem" }}>
            {renderRecipeHeadline(false, drag_handle_props)}
            {!fullScreen && (
              <Collapse in={open}>{open && renderRecipeDetails()}</Collapse>
            )}
            <SwipeableDrawer
              sx={{
                "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                  width: "100%",
                },
                display: { sm: "none" },
              }}
              anchor="right"
              open={drawer_open}
              onClose={() => {
                setDrawerOpen(false);
              }}
              onOpen={() => {
                setDrawerOpen(true);
              }}
              disableDiscovery
              disableSwipeToOpen
            >
              <Paper
                sx={{
                  p: "1rem",
                  height: "100%",
                  borderColor: "primary.main",
                  borderWidth: "4px",
                  borderStyle: "solid",
                  overflow: "auto",
                }}
              >
                {renderRecipeHeadline(true)}
                {renderRecipeDetails()}
              </Paper>
            </SwipeableDrawer>
          </Box>
        </Paper>
      </Box>
    );
  };

  const renderEventItemGroup = (drag_handle_props, is_dragging) => {
    return (
      <Box sx={{ pl: "1rem" }}>
        {!props.event_item.is_template && (
          <EventItemDividerButtons index={props.index} hidden={is_dragging} />
        )}
        <Paper
          sx={{
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            boxShadow: 3,
            backgroundColor: "rgba(231,237,233)",
          }}
        >
          <Paper
            display="flex"
            sx={{
              width: "100%",
              border: eventItemPriceIsLow(props.event_item)
                ? "solid 1px red"
                : undefined,
              backgroundColor: "rgba(231,237,233)",
              borderLeft: "3px solid",
              borderLeftColor: "secondary.main",
            }}
          >
            <Box sx={{ p: ".5rem" }}>
              {renderRecipeHeadline(false, drag_handle_props)}
              <Collapse in={open}>
                {open && !fullScreen && (
                  <Grid container>
                    <Grid item xs={12}>
                      {renderRecipeDetails()}
                    </Grid>
                  </Grid>
                )}
              </Collapse>
            </Box>
          </Paper>
          <Box sx={{ p: ".5rem", pt: 0, pb: ".01rem" }}>
            <SwipeableDrawer
              sx={{
                "& .css-1160xiw-MuiPaper-root-MuiDrawer-paper": {
                  width: "100%",
                },
                display: { sm: "none" },
              }}
              anchor="right"
              open={drawer_open}
              onClose={() => {
                setDrawerOpen(false);
              }}
              onOpen={() => {
                setDrawerOpen(true);
              }}
              disableDiscovery
              disableSwipeToOpen
            >
              <Paper
                sx={{
                  p: "1rem",
                  height: "100%",
                  borderColor: "primary.main",
                  borderWidth: "4px",
                  borderStyle: "solid",
                  overflow: "auto",
                }}
              >
                {renderRecipeHeadline(true)}
                <Grid container>
                  <Grid item xs="auto">
                    <EventItemSwitch
                      id="hide_sub_item_prices"
                      event_item={props.event_item}
                      updateEventItem={updateEventItem}
                      event={event}
                      true_label="Sub Item Prices"
                      false_label="Hide Sub Item Prices"
                      locked={eventLocked() || contractExists()}
                      reversed
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <EventItemSwitch
                      id="hide_sub_items"
                      event_item={props.event_item}
                      updateEventItem={updateEventItem}
                      event={event}
                      true_label="Sub Items"
                      false_label="Hide Sub Items"
                      locked={eventLocked() || contractExists()}
                      reversed
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {renderRecipeDetails()}
                  </Grid>
                </Grid>
              </Paper>
            </SwipeableDrawer>
            <Grid item xs={12}>
              {props.event_item.is_template ? (
                <DragDropContext onDragEnd={handleEventItemDragEnd}>
                  <EventItemsInGroup event_item={props.event_item} />
                </DragDropContext>
              ) : (
                <EventItemsInGroup event_item={props.event_item} />
              )}
              {props.event_item.event_items_in_group.length > 0 && (
                <EventItemDividerButtons
                  backgroundColor="white"
                  disableAddSection
                  disableAddGroup
                  eventItemGroup={props.event_item}
                />
              )}
            </Grid>
          </Box>
        </Paper>
        {draggable?.event_item_group === props.event_item.uuid && (
          <Droppable droppableId={`remove-from-group`} type="child">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Grow
                  in={draggable?.event_item_group === props.event_item.uuid}
                >
                  <Box
                    sx={{
                      borderStyle: "dotted",
                      borderColor: "info.light",
                      p: "2rem",
                      borderRadius: "7.5px",
                      mt: "1rem",
                    }}
                  >
                    <Typography sx={{ color: "info.light" }}>
                      Remove Item From Group
                    </Typography>
                    {provided.placeholder}
                  </Box>
                </Grow>
              </div>
            )}
          </Droppable>
        )}
      </Box>
    );
  };

  const getTaxGroups = () => {
    let tax_groups_ = event_version.tax_groups.map((tax_group) => ({
      ...tax_group,
      taxes: tax_group.taxes.map((tax) => tax.uuid),
    }));
    return tax_groups_.filter((tax_group) => {
      return tax_group.taxes.includes(props.event_item.tax_profile);
    });
  };

  const renderTax = () => {
    return (
      <Paper
        sx={{
          width: "100%",
          m: "auto",
          mt: "1rem",
        }}
      >
        <Box sx={{ p: "1rem" }}>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={11} sm={3} container alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <EventItemName
                  event_item={props.event_item}
                  updateEventItem={updateEventItem}
                  typographySx={
                    quantityField(props.event_item) === 0
                      ? {
                          color: "text.secondary",
                          textDecoration: "line-through",
                        }
                      : undefined
                  }
                  disabled={props.event_item.tax_profile}
                />
              </Grid>
              {getTaxGroups().map((tax_group) => (
                <Grid item xs="auto">
                  <Chip label={tax_group.name} size="small" />
                </Grid>
              ))}
              {quantityField(props.event_item) === 0 ? (
                <Grid item xs="auto">
                  <Chip label="Deleted" size="small" color="error" />
                </Grid>
              ) : null}
              {quantityField(props.event_item, true) !==
                quantityField(props.event_item, false) &&
              quantityField(props.event_item) > 0 ? (
                <Grid item xs="auto">
                  <Chip label="Added" size="small" color="error" />
                </Grid>
              ) : null}
              {quantityField(props.event_item, true) !==
                quantityField(props.event_item, false) && (
                <Grid item xs="auto">
                  <Tooltip
                    title={
                      props.event_item.quantity === 0
                        ? `Delete uncontracted ${_eventItemTypeName()}`
                        : `Reset ${_eventItemTypeName()}`
                    }
                  >
                    <IconButton
                      size="small"
                      sx={{ color: "error.main", pt: ".25rem" }}
                      onClick={resetEventItemQuantity}
                      disabled={eventLocked()}
                      id={formatStringForId(
                        props.event_item.name + "-reset-quantity-button"
                      )}
                    >
                      <RestartAltOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Grid item xs={6} sm={2}>
              <EventItemSelect
                id="is_percent"
                event_item={props.event_item}
                updateEventItem={updateEventItem}
                event={event}
                label="Tax Type"
                locked={eventLocked()}
                items={[
                  { value: true, label: "Percent" },
                  { value: false, label: "Amount" },
                ]}
                sx={{ maxWidth: "121px" }}
                disabled={props.event_item.tax_profile}
              />
            </Grid>

            <Grid item xs={6} sm={2}>
              {isPercentField(props.event_item) ? (
                <EventItemPercent
                  label="Tax Rate"
                  id="percent"
                  updateEventItem={updateEventItem}
                  event={event}
                  event_item={props.event_item}
                  disabled={props.event_item.tax_profile}
                />
              ) : (
                <EventItemNumber
                  label="Amount"
                  id="cost"
                  value={props.event_item.cost}
                  updateEventItem={updateEventItem}
                  event={event}
                  event_item={props.event_item}
                  disabled={props.event_item.tax_profile}
                  number
                />
              )}
            </Grid>

            <Grid item xs={5} sm={2} display="flex" container>
              <TextField
                label="Cost"
                value={formatCurrency(
                  suggestedTaxPrice(props.event_item, false)
                )}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  sx: {
                    textAlign: "center",
                    pl: ".25rem",
                    pr: ".25rem",
                  },
                }}
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={6} sm={2} display="flex" container>
              <EventItemPrice
                event_item={props.event_item}
                updateEventItem={updateEventItem}
                price={taxPrice(props.event_item, false)}
                suggested_price={suggestedTaxPrice(props.event_item, false)}
                event={event}
                contract_exists={contractExists()}
                sx={{ width: "7rem" }}
                label="Price"
                locked={eventLocked()}
              />
            </Grid>

            <Grid item xs={1}>
              <IconButton
                onClick={toggleDeletingEventItem}
                disabled={eventLocked()}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <DeleteEventItemDialog
          name={props.event_item.name}
          open={deleting_event_item}
          handleCancel={toggleDeletingEventItem}
          handleSubmit={removeEventItem}
          item_name="tax"
          item_will_be_deleted={
            !contractExists() || props.event_item.quantity === 0
          }
          is_tax
        />
      </Paper>
    );
  };

  const renderProposalRecipeLine = () => {
    const fontColor =
      props.proposal_section.style?.font_color || DefaultStyles.fontColor;
    const fontFamily =
      props.proposal_section.style?.font || DefaultStyles.fontFamily;
    return (
      <Box display="flex">
        <Grid container sx={{ width: "100%" }} spacing={1}>
          <Grid item xs={12}>
            <Grid container alignItems="center" sx={{ m: "auto" }} spacing={1}>
              <Grid item xs={8} sm={4}>
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: fontFamily,
                    color: fontColor,
                  }}
                >
                  {props.event_item.name}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={1}>
                {!props.event_item.is_percent && (
                  <Typography
                    display="flex"
                    sx={{
                      alignItems: "end",
                      lineHeight: "100%",
                      fontFamily: fontFamily,
                      color: fontColor,
                    }}
                  >
                    {quantityField(props.event_item)}
                    <Typography
                      sx={{
                        fontSize: ".5rem",
                        lineHeight: "100%",
                        fontFamily: fontFamily,
                        color: fontColor,
                      }}
                    >
                      qty
                    </Typography>
                  </Typography>
                )}
              </Grid>
              {proposal.show_recipe_price && !props.hide_sub_item_prices ? (
                <Grid item xs={8} sm={4}>
                  {!props.event_item.is_percent && (
                    <Typography
                      display="flex"
                      sx={{
                        alignItems: "end",
                        lineHeight: "100%",
                        fontFamily: fontFamily,
                        color: fontColor,
                      }}
                    >
                      {formatCurrency(eventItemPrice(props.event_item))}
                      <Typography
                        sx={{
                          fontSize: ".5rem",
                          lineHeight: "100%",
                        }}
                      >
                        each
                      </Typography>
                    </Typography>
                  )}
                </Grid>
              ) : null}
              {proposal.show_recipe_price && !props.hide_sub_item_prices ? (
                <Grid item xs={4} sm={3}>
                  <Typography
                    display="flex"
                    sx={{
                      alignItems: "end",
                      lineHeight: "100%",
                      fontFamily: fontFamily,
                      color: fontColor,
                    }}
                  >
                    {formatCurrency(
                      eventItemTotalPriceWithTax(props.event_item)
                    )}
                    <Typography
                      sx={{
                        fontSize: ".5rem",
                        lineHeight: "100%",
                      }}
                    >
                      total
                    </Typography>
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={6} sm={3}>
                <EventItemSwitch
                  id="is_optional"
                  event_item={props.event_item}
                  updateEventItem={updateEventItem}
                  event={event}
                  true_label="Optional"
                  false_label="Not Optional"
                  amendable
                  disabled={eventLocked()}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <EventItemSwitch
                  id="is_selected"
                  event_item={props.event_item}
                  updateEventItem={updateEventItem}
                  event={event}
                  true_label="Selected"
                  false_label="Not Selected"
                  disabled={!isOptionalField(props.event_item) || eventLocked()}
                  amendable
                />
              </Grid>
              {props.event_item.event_item_type === "GR" && (
                <>
                  {proposal.show_recipe_price && (
                    <Grid item xs="auto">
                      <EventItemSwitch
                        id="hide_sub_item_prices"
                        event_item={props.event_item}
                        updateEventItem={updateEventItem}
                        event={event}
                        true_label="Sub Item Prices"
                        false_label="Hide Sub Item Prices"
                        locked={eventLocked() || contractExists()}
                        reversed
                      />
                    </Grid>
                  )}
                  <Grid item xs="auto">
                    <EventItemSwitch
                      id="hide_sub_items"
                      event_item={props.event_item}
                      updateEventItem={updateEventItem}
                      event={event}
                      true_label="Sub Items"
                      false_label="Hide Sub Items"
                      locked={eventLocked() || contractExists()}
                      reversed
                    />
                  </Grid>
                </>
              )}
              {proposal.show_recipe_notes ? (
                <Grid item xs={12}>
                  <NotesSection
                    label="Proposal Notes (can optionally be added to your proposals)"
                    value={props.event_item.proposal_notes_rich_text}
                    event_recipe_update={updateEventItem}
                    id="proposal_notes_rich_text"
                    event_recipe_uuid={props.event_item.uuid}
                    rows={3}
                    color={fontColor}
                    font_family={fontFamily}
                    size="small"
                  />
                </Grid>
              ) : null}
              {props.event_item.recipe_imgs.length > 0 &&
              proposal.show_recipe_imgs ? (
                <Grid item xs={12} container spacing={1}>
                  <RecipeImgs
                    imgs={props.event_item.recipe_imgs}
                    id={formatStringForId(
                      props.event_item.name + "-inspiration-photos"
                    )}
                    zoom
                    displayOnly
                    height={50}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12} container>
                {!props.event_item.hide_sub_items &&
                  props.event_item.event_items_in_group?.map(
                    (event_item, index) => (
                      <Grid item xs={12}>
                        <Box sx={{ pl: "2rem" }}>
                          <EventItem
                            key={event_item.uuid}
                            event_item={event_item}
                            index={0}
                            backgroundColor={"white"}
                            disableAddSection
                            disableAddGroup
                            proposal
                            hide_sub_item_prices={
                              props.event_item.hide_sub_item_prices
                            }
                            last={
                              index ===
                              props.event_item.event_items_in_group.length - 1
                            }
                            proposal_section={props.proposal_section}
                          />
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
              <Grid item sm={12}>
                {!props.last && <Divider sx={{ m: ".5rem" }} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return ["RE", "GR", "OT"].includes(props.event_item.event_item_type) &&
    !props.proposal ? (
    props.disableDraggable ? (
      <Box>
        {["RE", "OT"].includes(props.event_item.event_item_type)
          ? renderRecipe()
          : props.event_item.event_item_type === "GR"
          ? renderEventItemGroup()
          : null}
      </Box>
    ) : (
      <Draggable draggableId={props.event_item.uuid} index={props.index}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              sx={{ p: "1rem", border: "1px solid black" }}
            >
              {["RE", "OT"].includes(props.event_item.event_item_type)
                ? renderRecipe(provided.dragHandleProps, snapshot.isDragging)
                : props.event_item.event_item_type === "GR"
                ? renderEventItemGroup(
                    provided.dragHandleProps,
                    snapshot.isDragging
                  )
                : null}
            </div>
          );
        }}
      </Draggable>
    )
  ) : props.tax && !props.proposal ? (
    renderTax()
  ) : (
    renderProposalRecipeLine()
  );
};

const EventItemsInGroup = (props) => {
  const [addOtherCost, addRecipe, drag_item_type, event_items, eventLocked] =
    useAccountState((state) => [
      state.addOtherCost,
      state.addRecipe,
      state.drag_item_type,
      state.event_items,
      state.eventLocked,
    ]);

  const handleAddRecipe = () => {
    addRecipe("New Recipe", props.event_item);
  };

  const handleAddOtherCost = () => {
    addOtherCost("New Other Cost", props.event_item);
  };

  return (
    <Droppable
      droppableId={`event-items:${props.event_item.uuid}`}
      type="child"
    >
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {props.event_item.event_items_in_group.length > 0 ? (
            props.event_item.event_items_in_group
              .sort(sort_by_order)
              .map((event_item, index) => {
                return (
                  <EventItem
                    key={event_item.uuid}
                    event_item={event_item}
                    index={index}
                    backgroundColor={"white"}
                    disableAddSection
                    disableAddGroup
                    eventItemGroup={props.event_item}
                  />
                );
              })
          ) : (
            <Box
              sx={{
                borderStyle: "dotted",
                borderColor: "info.light",
                p: "2rem",
                borderRadius: "7.5px",
                mt: ".5rem",
                mb: ".5rem",
              }}
            >
              <Typography
                sx={{
                  color: "info.light",
                  fontWeight: "normal",
                }}
                variant="h6"
              >
                No items have been added to this group.{" "}
                {!eventLocked() && "Drag one in or click to "}
                {!eventLocked() && (
                  <Link
                    onClick={handleAddRecipe}
                    color="inherit"
                    sx={{
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    Add Recipe
                  </Link>
                )}
                {!eventLocked() && " or "}
                {!eventLocked() && (
                  <Link
                    onClick={handleAddOtherCost}
                    color="inherit"
                    sx={{
                      "&:hover": { cursor: "pointer" },
                    }}
                  >
                    Add Cost
                  </Link>
                )}
              </Typography>
            </Box>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const EventItemNumber = (props) => {
  const [value, setValue] = React.useState(props.value);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const updateValue = (e) => {
    setValue(e.target.value);
  };

  const update = (e) => {
    var val = e.target.value;
    if (props.number && val === ".") {
      val = "0.";
    }
    props.updateEventItem({
      uuid: props.event_item.uuid,
      [props.id]: parseFloat(val),
    });
  };
  return (
    <TextField
      label={props.label}
      value={value}
      onChange={updateValue}
      onBlur={update}
      fullWidth
      inputProps={{
        sx: {
          pl: ".25rem",
          pr: ".25rem",
          textAlign: "center",
          ...props.inputPropsSx,
        },
      }}
      sx={props.sx}
      disabled={props.event.locked || props.disabled}
      onClick={stopClickProp}
    />
  );
};

const EventItemCostPercent = (props) => {
  const [updateEventItem, event] = useAccountState((state) => [
    state.updateEventItem,
    state.event,
  ]);
  const [value, setValue] = React.useState(
    percentField(props.event_item).toFixed(2)
  );

  React.useEffect(() => {
    setValue(percentField(props.event_item).toFixed(2));
  }, [percentField(props.event_item)]);

  const _updateEventItem = (data) => {
    setValue(data.percent);
    updateEventItem(data);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs="auto" display="flex">
          <AmendmentBadge
            tooltip={"Contracted Value: " + props.event_item.percent + "%"}
            invisible={percentField(props.event_item).equals(
              DecimalWrapper(props.event_item.percent)
            )}
          >
            <span>
              <EventItemNumber
                id="percent"
                value={value}
                updateEventItem={_updateEventItem}
                event={event}
                event_item={props.event_item}
                number
                inputPropsSx={{ p: ".3rem 0 .3rem 0" }}
                sx={{ width: "5rem", ml: "1rem" }}
              />
            </span>
          </AmendmentBadge>
        </Grid>
      </Grid>
      <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
        Cost (%){" "}
        <Tooltip title="Percent cost applied to the pre-tax price subtotal of recipes and fixed price costs.">
          <HelpOutlineOutlinedIcon fontSize="inherit" />
        </Tooltip>
      </Typography>
    </>
  );
};

const EventItemMarkupPercentage = (props) => {
  const [updateEventItem, event] = useAccountState((state) => [
    state.updateEventItem,
    state.event,
  ]);
  const [value, setValue] = React.useState(
    markupPercentageField(props.event_item).toFixed(2)
  );

  React.useEffect(() => {
    setValue(markupPercentageField(props.event_item).toFixed(2));
  }, [markupPercentageField(props.event_item)]);

  const _updateEventItem = (data) => {
    setValue(data.markup_percentage);
    updateEventItem(data);
  };

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs="auto" display="flex">
          <AmendmentBadge
            tooltip={
              "Contracted Value: " +
              markupPercentageField(props.event_item, true).toFixed(2) +
              "%"
            }
            invisible={markupPercentageField(props.event_item, false).equals(
              markupPercentageField(props.event_item, true)
            )}
          >
            <span>
              <EventItemNumber
                id="markup_percentage"
                value={value}
                updateEventItem={_updateEventItem}
                event={event}
                event_item={props.event_item}
                number
                inputPropsSx={{ p: ".3rem 0 .3rem 0" }}
                sx={{ width: "5rem", ml: "1rem" }}
              />
            </span>
          </AmendmentBadge>
        </Grid>
      </Grid>
      <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
        Markup (%){" "}
        <Tooltip title="Markup percent used to calculated Price/ea. This value will not affect Cost.">
          <HelpOutlineOutlinedIcon fontSize="inherit" />
        </Tooltip>
      </Typography>
    </>
  );
};

const EventItemPercent = (props) => {
  const update = (e) => {
    props.updateEventItem({
      uuid: props.event_item.uuid,
      [props.id]: e.target.value,
    });
  };
  return (
    <AmendmentBadge
      tooltip={"Contracted Rate: " + props.event_item.percent}
      invisible={
        props.event_item.amend_percent === null ||
        parseFloat(percentField(props.event_item)) ===
          parseFloat(props.event_item.percent)
      }
    >
      <TextField
        label={props.label}
        value={percentField(props.event_item)}
        onChange={update}
        fullWidth
        inputProps={{
          sx: {
            pl: ".25rem",
            pr: ".25rem",
            textAlign: "center",
          },
        }}
        disabled={props.event.locked || props.disabled}
      />
    </AmendmentBadge>
  );
};

const EventItemName = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [editing_name, setEditingName] = React.useState(false);
  const name_input = useRef(null);
  const updateName = () => {
    toggleEditingName();
    props.updateEventItem({
      uuid: props.event_item.uuid,
      name: name_input.current.value,
    });
  };
  const toggleEditingName = () => {
    setEditingName(!editing_name);
  };
  return (
    <Box sx={{ width: "100%" }}>
      {editing_name ? (
        <OutlinedInput
          onClick={stopClickProp}
          inputRef={name_input}
          onBlur={updateName}
          defaultValue={props.event_item.name}
          label="Recipe Name"
          id={props.event_item.uuid + "-name-input"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingName();
                }}
                edge="end"
              >
                <CloseOutlinedIcon fontSize=".25rem" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  updateName();
                }}
              >
                <CheckOutlinedIcon fontSize=".25rem" />
              </IconButton>
            </InputAdornment>
          }
        />
      ) : (
        <Tooltip
          title={props.event_item.name}
          placement="bottom"
          enterDelay={750}
        >
          <Typography
            onClick={
              !props.disabled
                ? (e) => {
                    e.stopPropagation();
                    toggleEditingName();
                  }
                : undefined
            }
            variant="h6"
            // noWrap
            color={
              isOptionalField(props.event_item) &&
              !isSelectedField(props.event_item)
                ? "text.secondary"
                : undefined
            }
            sx={props.typographySx}
          >
            {props.event_item.name}
            {!props.disabled ? (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditingName();
                }}
                id={formatStringForId(
                  props.event_item.uuid + "-name-edit-button"
                )}
              >
                <EditOutlinedIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

const EventItemQuantity = (props) => {
  const decreaseQuantity = (e) => {
    e.stopPropagation();
    let new_quantity = quantityField(props.event_item) - 1;
    if (new_quantity >= 0) {
      props.updateEventItem({
        uuid: props.event_item.uuid,
        quantity: new_quantity,
      });
    }
  };
  const increaseQuantity = (e) => {
    e.stopPropagation();
    props.updateEventItem({
      uuid: props.event_item.uuid,
      quantity: quantityField(props.event_item) + 1,
    });
  };
  const updateQuantity = (e) => {
    if (e.target.value !== "") {
      props.updateEventItem({
        uuid: props.event_item.uuid,
        quantity: parseInt(e.target.value),
      });
    }
  };
  return (
    <AmendmentBadge
      tooltip={"Contracted Quantity: " + props.event_item.quantity}
      invisible={quantityField(props.event_item) === props.event_item.quantity}
    >
      <Grid container justifyContent="center" alignItems={"center"}>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={decreaseQuantity}
            disabled={props.locked}
            id={formatStringForId(
              props.event_item.uuid + "-quantity-decrease-button"
            )}
          >
            <RemoveCircleOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        <Grid item xs="auto" textAlign={"center"}>
          <TextField
            value={quantityField(props.event_item)}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              sx: {
                textAlign: "center",
                p: ".25rem 0 0 0",
              },
            }}
            onChange={updateQuantity}
            // fullWidth
            sx={{ maxWidth: "5rem" }}
            label="qty."
            onClick={stopClickProp}
            disabled={props.locked}
            id={formatStringForId(props.event_item.name + "-quantity-input")}
          />
        </Grid>
        <Grid item xs="auto">
          <IconButton
            size="small"
            onClick={increaseQuantity}
            disabled={props.locked}
            id={formatStringForId(
              props.event_item.uuid + "-quantity-increase-button"
            )}
          >
            <AddCircleOutlineOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </AmendmentBadge>
  );
};

const EventItemPrice = (props) => {
  const [
    contractedEventItemPrice,
    eventItemPriceIsAmended,
    eventItemPriceIsLow,
    formatCurrency,
  ] = useAccountState((state) => [
    state.contractedEventItemPrice,
    state.eventItemPriceIsAmended,
    state.eventItemPriceIsLow,
    state.formatCurrency,
  ]);
  const [price, setPrice] = React.useState(parseFloat(props.price).toFixed(2));

  React.useEffect(() => {
    if (props.price || props.price === 0) {
      try {
        setPrice(props.price.toFixed(2));
      } catch {
        console.log("ERROR", props.price);
      }
    }
  }, [props.price]);

  const updatePrice = (e) => {
    setPrice(e.target.value);
  };

  const resetPrice = (e) => {
    e.stopPropagation();
    setPrice(props.contract_exists ? props.suggested_price : null);
    props.updateEventItem({
      uuid: props.event_item.uuid,
      price: props.contract_exists ? props.suggested_price : null,
    });
  };

  const handleBlur = (e) => {
    if (price) {
      try {
        props.updateEventItem({
          uuid: props.event_item.uuid,
          price: parseFloat(e.target.value),
        });
      } catch {
        console.log(price);
      }
    }
  };

  return (
    <Box
      display="flex"
      id={formatStringForId(
        props.event_item.name + "-price-ea-input-container"
      )}
    >
      <AmendmentBadge
        tooltip={
          "Contracted Price: " + contractedEventItemPrice(props.event_item)
        }
        invisible={!eventItemPriceIsAmended(props.event_item)}
      >
        <TextField
          label={props.label}
          value={
            props.price || props.price === 0
              ? price
              : props.suggested_price.toFixed(2)
          }
          inputProps={{
            inputMode: "decimal",
            sx: {
              textAlign: "center",
              ...props.inputPropsSx,
            },
            id: formatStringForId(props.event_item.name + "-price-ea-input"),
          }}
          onChange={updatePrice}
          onBlur={handleBlur}
          sx={props.sx}
          onClick={stopClickProp}
          fullWidth
          InputProps={{
            endAdornment:
              priceNotEqualSuggested(props.event_item, props.suggested_price) &&
              !priceIsDerived(props.event_item) &&
              (eventItemPriceIsLow(props.event_item) ||
                props.event_item.event_item_type === "TA") ? (
                <InputAdornment position="end">
                  <Tooltip
                    title={
                      props.event_item.event_item_type === "TA"
                        ? "Tax price is not equal to calculated tax cost: " +
                          formatCurrency(props.suggested_price)
                        : "Price is below suggested retail price: " +
                          formatCurrency(props.suggested_price)
                    }
                  >
                    <WarningAmberRoundedIcon
                      fontSize=".25rem"
                      sx={{ color: "error.main" }}
                    />
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
          disabled={
            props.locked ||
            (props.event_item.event_item_type === "GR" &&
              !props.event_item.hide_sub_item_prices &&
              !props.event_item.hide_sub_items) ||
            props.event_item.event_item_type === "TA"
          }
          id={formatStringForId(props.event_item.name + "-price-input")}
        />
      </AmendmentBadge>
      {priceField(props.event_item) !== null &&
      !priceIsDerived(props.event_item) &&
      (!props.event_item.is_percent ||
        props.event_item.event_item_type === "TA") ? (
        <Tooltip
          title={
            "Connect price to formula. Suggested retail price: " +
            formatCurrency(props.suggested_price)
          }
        >
          <IconButton
            id={formatStringForId(
              props.event_item.uuid + "-price-reset-button"
            )}
            size="small"
            onClick={resetPrice}
            disabled={props.locked}
          >
            <RestartAltOutlinedIcon
              fontSize="inherit"
              sx={{
                color: eventItemPriceIsLow(props.event_item)
                  ? "error.main"
                  : undefined,
              }}
            />
          </IconButton>
        </Tooltip>
      ) : null}
    </Box>
  );
};

const EventItemSwitch = (props) => {
  const value = () => {
    return props.amendable
      ? amendableField(props.event_item, props.id)
      : props.event_item[props.id];
  };

  const contracted = () => {
    return props.event_item[props.id];
  };

  const toggle = () => {
    props.updateEventItem({
      uuid: props.event_item.uuid,
      [props.id]: !value(),
    });
  };
  return (
    <AmendmentBadge
      tooltip={
        "Contracted Value: " +
        (contracted() ? props.true_label : props.false_label)
      }
      invisible={value() === contracted()}
    >
      <FormControlLabel
        id={formatStringForId(
          props.event_item.name + "-" + props.id + "-switch-container"
        )}
        control={
          <Switch
            checked={props.reversed ? !value() : value()}
            onChange={toggle}
            onClick={stopClickProp}
            disabled={props.locked || props.disabled}
            id={formatStringForId(
              props.event_item.name + "-" + props.id + "-switch"
            )}
            size="small"
          />
        }
        label={props.label ? props.label : props.true_label}
      />
    </AmendmentBadge>
  );
};

const EventItemSelect = (props) => {
  const value = () => {
    return amendableField(props.event_item, props.id);
  };

  const contracted = () => {
    const ind = props.items.findIndex(
      (e) => e.value === props.event_item[props.id]
    );
    return props.items[ind].label;
  };

  const handleChange = (e) => {
    props.updateEventItem({
      uuid: props.event_item.uuid,
      [props.id]: e.target.value,
    });
  };
  return (
    <AmendmentBadge
      tooltip={"Contracted Value: " + contracted()}
      invisible={value() === props.event_item[props.id]}
      placement={"top"}
    >
      <Box sx={{ minWidth: 130 }}>
        <FormControl fullWidth>
          <InputLabel id={props.id}>{props.label}</InputLabel>
          <Select
            id={props.id}
            onChange={handleChange}
            // onClick={stopClickProp}
            label={props.label}
            disabled={props.locked || props.disabled}
            value={value()}
            sx={props.sx}
          >
            {props.items.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </AmendmentBadge>
  );
};

const RecipeHeaderValue = (props) => {
  return (
    <Box>
      <Typography
        variant="h6"
        style={{ textAlign: "center", alignItems: "center" }}
        sx={
          props.strikeThrough
            ? { color: "text.secondary", textDecoration: "line-through" }
            : null
        }
        id={props.label + "-value"}
      >
        {props.value}
        {props.endAdornment}
      </Typography>
      <Typography
        style={{ textAlign: "center" }}
        sx={{ color: "text.secondary" }}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

const SubtotalValue = (props) => {
  return (
    <Box id={props.id}>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {props.value}
      </Typography>
      <Typography
        style={{ textAlign: "center" }}
        sx={{ color: "text.secondary" }}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export const ExpandButton = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} sx={{ p: 0, m: "auto" }} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DeleteEventItemDialog = (props) => {
  const handleSubmit = () => {
    props.handleSubmit();
    props.handleCancel();
  };
  return (
    <Dialog onClose={props.handleCancel} open={props.open}>
      <DialogTitle>
        Are you sure you want to delete this {props.item_name}?
      </DialogTitle>
      <DialogContent>
        <Typography>
          {props.item_will_be_deleted
            ? "This action is permanent and cannot be undone."
            : props.is_tax
            ? "Since this tax is contracted, the value will be set to zero and an amendment will be generated."
            : "Since this item is contracted, the quantity will be set to zero and an amendment will be generated."}
        </Typography>
        {props.item_will_be_deleted &&
          props.event_item?.event_item_type === "GR" && (
            <Alert severity="warning">
              This will also delete all sub items in this group.
            </Alert>
          )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs="auto">
            <Button
              onClick={props.handleCancel}
              variant="outlined"
              color="info"
              id="cancel-delete-event-item"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              onClick={handleSubmit}
              variant="outlined"
              color="error"
              id="confirm-delete-event-item"
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

function RecipeDetailsSection(props) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Divider sx={{ mt: ".5rem", mb: ".5rem" }} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={11} onClick={toggleOpen}>
          <Typography
            variant="h7"
            sx={{
              fontFamily: "Roboto",
              "&:hover": { cursor: "pointer" },
            }}
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={"auto"} onClick={toggleOpen}>
          <ExpandButton
            expand={open}
            size="small"
            onClick={toggleOpen}
            id={props.id + "-expand-button"}
          >
            <KeyboardArrowDownOutlinedIcon fontSize="inherit" />
          </ExpandButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={open} id={props.id + "-collapse"}>
            {props.children}
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}

export function NotesSection(props) {
  const [value, setValue] = React.useState(props.value ? props.value : "");

  React.useEffect(() => {
    setValue(props.value ? props.value : "");
  }, [props.value]);

  const updateLocalNotes = (e) => {
    setValue(e.target.value);
  };

  const updateNotes = (data) => {
    props.event_recipe_update({
      uuid: props.event_recipe_uuid,
      [props.id]: data,
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          sx={{ color: "info.main", fontSize: ".75rem", fontWeight: "bold" }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RichTextFieldEditable
          value={value}
          updateValue={updateNotes}
          autoFocus
          font_color={props.color}
          font={props.font_family}
          placeholder={`Click to add ${props.label?.split("(")[0]}`}
        />
      </Grid>
    </Grid>
  );
}

const stopClickProp = (e) => {
  e.stopPropagation();
};

const EventItemPriceWarningDialog = (props) => {
  const [
    contractExists,
    event,
    eventItemPrice,
    eventLocked,
    formatCurrency,
    suggestedEventItemPrice,
    updateEventItem,
  ] = useAccountState((state) => [
    state.contractExists,
    state.event,
    state.eventItemPrice,
    state.eventLocked,
    state.formatCurrency,
    state.suggestedEventItemPrice,
    state.updateEventItem,
  ]);

  const handleSubmit = (e) => {
    props.handleCancel(e);
    updateEventItem({
      uuid: props.event_item.uuid,
      [contractExists() ? "amend_price" : "price"]: contractExists()
        ? suggestedEventItemPrice(props.event_item)
        : null,
    });
  };
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Price of "{props.event_item.name}" is too low</DialogTitle>
      <DialogContent>
        <Typography>
          The price you have entered for "{props.event_item.name}" is{" "}
          {formatCurrency(eventItemPrice(props.event_item))} which is below the
          suggested retail price of{" "}
          {formatCurrency(suggestedEventItemPrice(props.event_item))}. To
          resolve this, choose one of the following options:
          <List dense>
            <ListItem>
              <ListItemIcon>
                <CircleOutlinedIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText>
                Click the Update Price button below to set the price to the
                suggested retail price
                {event.status !== "IN" &&
                  event.status !== "PR" &&
                  " (this will generate an amendment)"}
                .
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CircleOutlinedIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText>
                Expand the card and update the Price/ea. field to be equal to or
                greater than the suggested retail price
                {event.status !== "IN" &&
                  event.status !== "PR" &&
                  " (this will generate an amendment)"}
                .
              </ListItemText>
            </ListItem>
            {props.event_item.event_item_type === "RE" && (
              <ListItem>
                <ListItemIcon>
                  <CircleOutlinedIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText>
                  Adjust the flowers, hard goods or labor estimates so that the
                  suggested retail price falls below the set price.
                </ListItemText>
              </ListItem>
            )}
            {props.event_item.event_item_type === "OT" && (
              <ListItem>
                <ListItemIcon>
                  <CircleOutlinedIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText>
                  Adjust the cost or markup so that the suggested retail price
                  falls below the set price.
                </ListItemText>
              </ListItem>
            )}
            {props.event_item.event_item_type === "GR" && (
              <ListItem>
                <ListItemIcon>
                  <CircleOutlinedIcon fontSize="inherit" />
                </ListItemIcon>
                <ListItemText>
                  Adjust the sub-item prices or quantities so that the suggested
                  retail price of the group drops below the set price.
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Typography>
        <Alert severity="info">
          {props.event_item.event_item_type === "GR" && (
            <Typography>
              For groups, the suggested retail price is calculated using the sum
              of the group's sub-item prices. In this case, the sum of the
              sub-item prices is greater than the price you are charging for the
              group.
            </Typography>
          )}
          {props.event_item.event_item_type === "RE" && (
            <Typography>
              For recipes, the suggested retail price is calculated using the
              sum of flower costs, hard good costs, and labor costs, plus any
              markups or design fees you have configured in your event settings.
            </Typography>
          )}
          {props.event_item.event_item_type === "OT" && (
            <Typography>
              For costs, the suggested retail price is calculated using the cost
              and markup you specified.
            </Typography>
          )}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Do Nothing
        </Button>
        {!eventLocked() && (
          <Button onClick={handleSubmit} color="secondary" variant="contained">
            Update Price To Suggested
          </Button>
        )}
        {eventLocked() && (
          <Tooltip title="Unlock event to update price">
            <span>
              <Button
                disabled
                color="secondary"
                variant="contained"
                sx={{ ml: ".5rem" }}
              >
                Update Price To Suggested
              </Button>
            </span>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const CreateEventItemTemplateDialog = (props) => {
  const [deleteEventItem, updateEventItem] = useAccountState((state) => [
    state.deleteEventItem,
    state.updateEventItem,
  ]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [deleting, setDeleting] = React.useState(false);

  const handleCancel = () => {
    deleteEventItem(props.event_item.uuid, props.handleCancel());
  };

  const [templateName, setTemplateName] = React.useState(
    props.event_item?.template_name
  );

  React.useEffect(() => {
    setTemplateName(props.event_item?.template_name);
  }, [props.event_item.template_name]);

  const handleTemplateNameChange = (e) => {
    setTemplateName(e.target.value);
  };

  const handleTemplateNameBlur = () => {
    updateEventItem({
      uuid: props.event_item.uuid,
      template_name: templateName,
    });
  };

  const onClose = () => {
    props.handleCancel();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={onClose}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>{props.edit ? "Modify" : "Create"} Template</DialogTitle>
        <DialogContent
          sx={fullScreen ? { pl: ".25rem", pr: ".25rem" } : undefined}
        >
          {props.event_item ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <EventItem event_item={props.event_item} disableDraggable />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Template Name"
                  value={templateName}
                  onChange={handleTemplateNameChange}
                  onBlur={handleTemplateNameBlur}
                  fullWidth
                  size="small"
                  sx={{ mt: "1rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Tags event_item={props.event_item} />
              </Grid>
            </Grid>
          ) : (
            <Box display={"flex"}>
              <CircularProgress sx={{ m: "auto" }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={"space-between"}>
            <Grid item xs="auto">
              <Button
                onClick={() => setDeleting(true)}
                color="error"
                variant="outlined"
              >
                {!props.edit ? "Cancel" : "Delete"}
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={props.handleCancel}
                color="secondary"
                variant="contained"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <DeleteEventItemTemplateDialog
        open={deleting}
        handleCancel={() => setDeleting(false)}
        handleDelete={handleCancel}
        event_item={props.event_item}
      />
    </>
  );
};

const DeleteEventItemTemplateDialog = (props) => {
  return (
    <Dialog open={props.open} onClose={props.handleCancel}>
      <DialogTitle>Delete Template</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the template "
          {props.event_item.template_name}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCancel} color="info" variant="outlined">
          Cancel
        </Button>
        <Button onClick={props.handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withRouter(EventItem);
